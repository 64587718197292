import * as React from "react"
import "twin.macro"
import { useState } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import Transition from "../Transition"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

type Person = {
  name: string
  position: string
  bio: React.ReactNode
}

const defaultPeople: Person[] = [
  {
    name: "Nikhil Sathye",
    position: "President",
    bio:
      "Hey everyone! My name is Nikhil and I will be serving as your President this year. I'll be overseeing the operations of the club and making sure that all of you guys enjoy your time in MVFBLA! Outside of FBLA, I really enjoy traveling, biking, watching YouTube, and listening to music. I look forward to meeting you guys this year and I hope I can help make your time in MVFBLA incredibly memorable!!! Please reach out if you have any questions or any Netflix/music recommendations. ",
  },
  {
    name: "Manvi Kottakota",
    position: "Executive Vice President",
    bio:
      "Hey Everyone, I'm Manvi and I am super excited to kickstart our year as the Executive Vice President of MVFBLA! I will be responsible for overseeing the club's operations and working with Nikhil to ensure overall club success :) In my free time, you may find me watching any show from the bachelor franchise, creating random spotify playlists, or adding to my collection of house plants that I never water and eventually die. I would love to meet all of you, so please feel free to reach out to just chill or talk anytime!",
  },
  {
    name: "Marissa Jensen",
    position: "VP of Competitions",
    bio:
      "Hi everyone, I’m Marissa, MVFBLA’s VP of Competitions for the 2020-2021 school year! As the head of our competitions branch, I’ll be working with all of our members, creating competition teams, organizing study sessions, and ensuring that everyone is getting the most out of their competitive experience. When I’m not busy with school activities, you can find me outdoors either hiking, surfing (I’m not very good but I try), skateboarding, and playing volleyball. If you have any questions regarding the competitions MVFBLA participates in, don’t hesitate to reach out :) ",
  },
  {
    name: "Rachael Ding",
    position: "Director of Impromptu Competitions",
    bio:
      "Hello! I'm Rachael and I'm your director of impromptu comps. I'll be in charge of your interviews, study sessions, and impromptu events. A little bit about me: I'm an art enthusiast, lover of good stories, and enjoyer of life. Feel free to hit me up if you ever need help or just wanna chat ;) Looking forward to making some unforgettable memories this year!",
  },
  {
    name: "Lydia Lu",
    position: "Director of Prejudged Competitions",
    bio:
      "Hola! My name is Lydia and I am serving as this year's Director of Prejudged Competitions! This year I will be helping prejudged teams make their way to nationals😎 Outside of FBLA, I enjoy baking, music, and dance. I am a Panera enthusiast and can be found there 24/7. I also love the outdoors, and spending time with my friends and family. ",
  },
  {
    name: "Rohit Dayanand",
    position: "Director of Individual Oral Competitions",
    bio:
      "Hey im Rohit. I'll be the director of individual oral competitions  this year. t - I love to cook, talk + watch political comedy in my free time. Excited to meet ya'll! ",
  },
  {
    name: "Zayd Musa",
    position: "Director of Individual Objective Competitions",
    bio:
      "Hi, I'm Zayd, your Director of Individual Objective Competitions! In this role, I will help the other comps officers to conduct interviews, host study sessions and help you through your FBLA journey. I specifically help objective competitors to work hard and succeed at leadership conferences. In my free time, I will probably be painting, writing, watching TV or playing with my (ABSOLUTELY ADORABLE) cat, Simba. Feel free to reach out if you need help or if you want some cat pics!",
  },
  {
    name: "Anya Goyal",
    position: "VP of Operations",
    bio:
      "Hello everyone! I’m Anya and I will be this year’s Vice President of Operations. I co-lead the rest of the Operations branch, where we handle member registration and finances as well as plan super cool socials. Outside of FBLA, you can usually find me chilling with my dog or napping at outrageous times of day. I also enjoy listening to music, and you can usually find me in my room with my headphones on, jamming to my alt-rock Spotify playlist. I’m really looking forward to this year and I love to meet new people, so if you’d like to ask a question or just chat, feel free to reach out!",
  },
  {
    name: "June Wang",
    position: "VP of Marketing",
    bio:
      "Hi, I’m June! As the Vice President of Marketing, I co-lead logistics and member engagement and recruitment strategies. In my free time, I enjoy dancing and experimenting with new foods; I'm always excited to explore cuisines and their surrounding cultures. Want to know why MVFBLA is the best club on campus (besides being ranked Top 6 nationally)? Or debate on the ideal thickness of ramen noodles? Let's talk sometime. :)",
  },
  {
    name: "Grace Wang",
    position: "Director of Public Relations/Design",
    bio:
      "Hi everyone!! My name is Grace and I will be your Director of PR/Design this year, meaning I will manage the FBLA social media accounts, design any merchandise, promotional graphics, etc, and co-plan any member social and bonding events!! I also help with the logistics of the club together with the operations branch! In my spare time, I like to play bball, read, and ADMIRE MY CATS! Feel free to dm me if you have any questions about what I do specifically, or any general fbla questions!! Also please please please talk to me if you want to see pictures of my cats!!!!!",
  },
  {
    name: "Kushagra Srivastava",
    position: "Director of Member Relations",
    bio:
      "HELLO LOVELY PEOPLE! My name is Kushagra Srivastava and I will be this year's Director of Member Relations. In position, I will be in-charge as anything and everything related to members. This includes events/meetings, logistics directly related to members,  creating connections within the club, and more! I will also be in-charge of keeping this beautiful website up to date. In my free time, I love to cook, read, hike, explore, and spend tons of time with family. My favorite show of all time is \"Psych\". I am Extremely PUMPED for Monta Vista FBLA and can’t wait to meet y’all!",
  },
  {
    name: "Vidusha Adira",
    position: "Secretary-Treasurer",
    bio:
      "Hi everyone! My name is Vidusha and I'll be your secretary-treasurer this year, which means I'll be handling the club's finances and taking meeting minutes, as well as working with the rest the ops branch to plan fun socials and handle logistics.  Outside of FBLA, I love listening to music and cooking.  If you can't find me I'll most likely be in the kitchen trying to figure out how to make a recipe without half of the ingredients.  I love binge-watching shows (my favorite I watched recently was Young Royals on Netflix).  I'm looking forward to meeting you all this year!",
  },
  {
    name: "Cindy Zou",
    position: "VP of Projects",
    bio:
      "Hey y’all! I’m Cindy and I’ll be serving at the Vice President of Projects! I’ll be working with the Projects branch this year to organize exciting events for MVFBLA’s three chapter-wide initiatives (American Enterprise, Community Service, Partnership with Business), as well as State Projects. Outside of FBLA, I enjoy going on spontaneous trips with friends, binge-watching crime shows, and spending time with my cats, Kewpie and Goma. I’m so excited for what’s in store for MVFBLA, and even more excited to see all of you!",
  },
  {
    name: "Ankita Chaugule",
    position: "Director of State Projects",
    bio:
      "Hey guys!! My name is Ankita and I am currently a junior at Monta Vista High School. I'm this year's Director of State Projects. I can't wait to see some of y'all apply to join soon. State projects are noncompetitive and overall very fun! But enough about state projects here's a little bit about me. I love cooking (even though I'm terrible at it), binging Netflix TV Shows (New Girl is my all-time favorite show), and listening to music. In addition, I have been dancing for over 11 years. That's all for me!! ",
  },
  {
    name: "Trisha Sreedhar",
    position: "Director of American Enterprise Project",
    bio:
      "Hey everyone, I’m Trisha! As Director of the American Enterprise Project, I lead the various activities of the project including Shark Tank and financial literacy workshops. This year, we are focusing on increasing the confidence of middle and high school students in entrepreneurship! In my free time, you can find me reading realistic fiction, eating potato chips, watching tv shows/movies, and getting random bruises.  Reach out if you have any questions, or if you want to chat (about anything). I’m excited to meet you all! :)",
  },
  {
    name: "Smriti Rangarajan",
    position: "Director of Community Service Project",
    bio:
      "Hi everyone! I'm Smriti and I'll be the Director of the Community Service Project this year! Some of my responsibilities include planning, and directing various events in order to create an impact on our community! The Community Service project is a great way to serve the community using a business outlook and gain valuable leadership skills as well! Outside of FBLA, you can find me bingeing TV Shows (my quarantine passion project was watching 16 seasons of Grey's Anatomy), trying new foods, obsessing over ink pens, and re organizing my room. I'm so excited for the upcoming year, and feel free to reach out if you have any questions! ",
  },
  {
    name: "Ariyal Jain",
    position: "Director of Partnership with Business Project",
    bio:
      "Hey guys, I'm Ari! I am a senior this year and I am extremely excited to start it off partnering with an award winning creative marketing agency- 21 Grams. PWB, which is short for Partnership With Business entails many different workshops and opportunities, including feature/project designing, branding, business planning, marketing, research and internships. Sooo you guys should definitely join :)) Anyways, on another note, I love reading, dancing, swimming, bingeing netflix, eating junk food, and doing taekwando. I can't wait to have another great year in FBLA! Also, if you guys ever have any questions or just want someone to talk to, I'm here for you.",
  },
]

const ModalBackground = styled.div`
  ${tw`fixed inset-0 transition-opacity`}

  &.enter {
    ${tw`ease-out duration-300`}
  }
  &.enterFrom {
    ${tw`opacity-0`}
  }
  &.enterTo {
    ${tw`opacity-100`}
  }
  &.leave {
    ${tw`ease-in duration-200`}
  }
  &.leaveFrom {
    ${tw`opacity-100`}
  }
  &.leaveTo {
    ${tw`opacity-0`}
  }
`

const ModalContainer = styled.div`
  ${tw`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6`}

  &.enter {
    ${tw`ease-out duration-300`}
  }
  &.enterFrom {
    ${tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`}
  }
  &.enterTo {
    ${tw`opacity-100 translate-y-0 sm:scale-100`}
  }
  &.leave {
    ${tw`ease-in duration-200`}
  }
  &.leaveFrom {
    ${tw`opacity-100 translate-y-0 sm:scale-100`}
  }
  &.leaveTo {
    ${tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`}
  }
`

const TeamBioModal = ({
  show,
  person,
  onClose,
}: {
  show: boolean
  person: Person
  onClose: Function
}) => {
  return (
    <Transition show={show}>
      <div tw="fixed z-10 inset-0 overflow-y-auto">
        <div tw="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition
            enter="enter"
            enterFrom="enterFrom"
            enterTo="enterTo"
            leave="leave"
            leaveFrom="leaveFrom"
            leaveTo="leaveTo"
          >
            <ModalBackground onClick={onClose}>
              <div tw="absolute inset-0 bg-gray-500 opacity-75" />
            </ModalBackground>
          </Transition>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span tw="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <Transition
            enter="enter"
            enterFrom="enterFrom"
            enterTo="enterTo"
            leave="leave"
            leaveFrom="leaveFrom"
            leaveTo="leaveTo"
          >
            <ModalContainer
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div tw="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  tw="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  aria-label="Close"
                  onClick={onClose}
                >
                  <svg
                    tw="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div tw="sm:flex sm:items-start">
                <div tw="text-center sm:text-left">
                  <h3
                    tw="text-xl leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    {person.name}, {person.position}
                  </h3>
                  <div tw="mt-6">
                    <p tw="leading-6 text-gray-500">{person.bio}</p>
                  </div>
                </div>
              </div>
            </ModalContainer>
          </Transition>
        </div>
      </div>
    </Transition>
  )
}

export default ({ people = defaultPeople }) => {
  const [showBio, setShowBio] = useState(false)
  const [person, setPerson] = useState(people[0])

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/team/.*/" } }) {
        edges {
          node {
            childImageSharp {
              fixed(width: 400, height: 300, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
            name
          }
        }
      }
    }
  `)
  const photos = {}
  for (let person of people) {
    let node = data.allFile.edges.find(
      x => x.node.name === person.name.split(" ")[0]
    )
    if (!node)
      console.warn("Couldn't find image for " + person.name.split(" ")[0])
    else photos[person.name] = node.node.childImageSharp
  }

  return (
    <div tw="bg-white">
      <div tw="mx-auto py-12 px-4 max-w-screen-xl sm:px-6 lg:px-8 lg:py-24">
        <div tw="space-y-12">
          <div tw="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 tw="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl">
              2021-2022 Officer Team
            </h2>
            {/*<p tw="text-xl leading-7 text-gray-500">*/}
            {/*  Odio nisi, lectus dis nulla. Ultrices maecenas vitae rutrum dolor*/}
            {/*  ultricies donec risus sodales. Tempus quis et.*/}
            {/*</p>*/}
          </div>
          <ul tw="space-y-12 sm:grid sm:grid-cols-2 sm:col-gap-6 sm:row-gap-12 sm:space-y-0 lg:grid-cols-3 lg:col-gap-8">
            {people.map(person => (
              <li key={person.name}>
                <div tw="space-y-4">
                  <div tw="relative pb-2/3">
                    <div tw="absolute h-full w-full shadow-lg rounded-lg overflow-hidden">
                      {photos[person.name] && (
                        <Img
                          fixed={photos[person.name].fixed}
                          objectFit="cover"
                          alt=""
                          style={{ width: "100%" }}
                        />
                      )}
                    </div>
                  </div>

                  <div tw="space-y-2">
                    <div tw="text-lg leading-6 font-medium space-y-1">
                      <h4>
                        {person.name}, {person.position}
                      </h4>
                      <button
                        tw="text-indigo-600 text-base font-medium hover:text-indigo-500"
                        onClick={() => {
                          setPerson(person)
                          setShowBio(true)
                        }}
                      >
                        Read Bio &rarr;
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <TeamBioModal
        show={showBio}
        person={person}
        onClose={() => setShowBio(false)}
      />
    </div>
  )
}
