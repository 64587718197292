import * as React from "react"
import "twin.macro"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/About/Hero"
import Team from "../components/About/Team"
import FAQ from "../components/FAQ"
import WhatWeDo from "../components/About/WhatWeDo"
import CTAJoinUs from "../components/CTAJoinUs"

export default function AboutPage() {
  return (
    <Layout>
      <SEO title="About Us" />
      <Hero />
      <WhatWeDo />
      <CTAJoinUs />
      <Team />
    </Layout>
  )
}
