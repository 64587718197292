import * as React from "react"
import "twin.macro"
import { Link } from "gatsby"
import heroBg from "../../images/officers.jpg"

export default function Hero() {
  return (
    <div tw="relative bg-gray-50 overflow-hidden">
      <div tw="absolute inset-0">
        <img src={heroBg} />
      </div>
      <div tw="z-10 absolute inset-0 bg-black opacity-75" />
      <div tw="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32 z-20">
        <main tw="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
          <div tw="text-center mb-12">
            <h2 tw="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl">
              About MVFBLA
            </h2>
          </div>
          <div tw="prose prose-lg mx-auto text-blue-300">
            <p>
              Ranking 4th in the nation, Monta Vista Future Business Leaders of
              America is a business-focused organization that creates endless{" "}
              <b tw="text-white">opportunities</b> for Monta Vista High School
              students. As a member, students can learn, explore, and experience
              business and leadership through our various{" "}
              <b tw="text-white">
                competitions, projects, and networking opportunities
              </b>
              . We strive to foster growth and assist students in the
              establishment of career goals by promoting the development of
              lifelong skills needed beyond high school. Our goal is to
              encourage members to improve their homes, businesses, and
              communities. Whether it’s competing at fun conferences with your
              friends or networking with professionals at companies such as
              Facebook, MVFBLA truly does have a lot to offer.{" "}
              <Link to="/join" tw="text-white! underline!">
                Join us today!
              </Link>
            </p>
          </div>
        </main>
      </div>
    </div>
  )
}
