import * as React from "react"
import "twin.macro"
import { Link } from "gatsby"

export default function WhatWeDo() {
  return (
    <div tw="my-20">
      <div tw="max-w-screen-xl mx-auto grid lg:grid-cols-2 gap-12 px-4 sm:px-6 md:px-8">
        <div tw="flex flex-col">
          <div tw="flex-1">
            <span tw="text-blue-600 uppercase font-bold">Competitions</span>
            <h2 tw="text-4xl font-black tracking-wide mb-4">Compete to win.</h2>
            <p className="prose-lg" tw="text-gray-600 mb-4">
            With 70+ national competitions spanning global business, sports and marketing management, 
            computer science, healthcare, agribusiness, economics, and more under impromptu, pre-judged, 
            group, and individual categories, our members find boundless opportunities to display their 
            skills and win national awards. Each year, most of our members travel to Chicago, Atlanta, Orlando, 
            Anaheim, San Antonio, and other major cities to compete against chapters worldwide and win 
            international awards. 
            </p>
          </div>

          <div tw="flex-shrink-0">
            <Link
              to="/competitions"
              tw="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-700 bg-blue-100 hover:text-blue-600 hover:bg-blue-50 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Explore Competitions
            </Link>
          </div>
        </div>
        <div tw="flex flex-col">
          <div tw="flex-1">
            <span tw="text-blue-600 uppercase font-bold">Projects</span>
            <h2 tw="text-4xl font-black tracking-wide mb-4">
              Be the difference.
            </h2>
            <p className="prose-lg" tw="text-gray-600 mb-4">
              MVFBLA provides Projects, encompassing of American Enterprise, Community Service,
              and Partnership with Business. Past partnerships for our projects include Facebook, 
              multiple venture-backed startups, and companies spanning various industries and backgrounds. 
              Members serving on Projects committees host competitions backed with thousands of dollars, 
              work with the C-Suite of companies, and leave significant impact in whichever community they 
              choose to serve.
            </p>
          </div>

          <div tw="flex-shrink-0">
            <Link
              to="/projects"
              tw="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-700 bg-blue-100 hover:text-blue-600 hover:bg-blue-50 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Expore Projects
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
