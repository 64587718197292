import * as React from "react"
import "twin.macro"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function CTAJoinUs() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "join_us_img.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div tw="relative bg-gray-800">
      <div tw="h-56 bg-blue-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <Img
          tw="w-full h-full object-cover"
          fluid={data.file.childImageSharp.fluid}
          alt="FBLA Family"
        />
      </div>
      <div tw="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div tw="md:ml-auto md:w-1/2 md:pl-10">
          <div tw="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
            Excited to Get Started?
          </div>
          <h2 tw="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
            Fill out our form with your name and email.
          </h2>
          {/*<p tw="mt-3 text-lg leading-7 text-gray-300">*/}
          {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et, egestas*/}
          {/*  tempus tellus etiam sed. Quam a scelerisque amet ullamcorper eu enim*/}
          {/*  et fermentum, augue. Aliquet amet volutpat quisque ut interdum*/}
          {/*  tincidunt duis.*/}
          {/*</p>*/}
          <div tw="mt-8">
            <div tw="inline-flex rounded-md shadow">
              <Link
                to="/join"
                tw="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              >
                Join Today!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
